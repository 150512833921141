import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Avatar, Badge, IconButton, Skeleton, Stack, TableCell, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import NanoMenuItem from '../../../shared/components/NanoMenuItem'
import {
  CalibrateIcon,
  OrderIcon,
  SiloIcon,
  Battery33PercentIcon,
  NotificationIcon,
} from '../../../shared/icons'
import { ToastContext } from '../../../shared/contexts'
import { useCalibDeliveryModal } from '../../device/components/CalibDeliveryModal'
import { useDeviceModal } from '../../dashboard/DeviceModal'
import useNewTabNavigate from '../../../shared/customHooks/useNewTabNavigate'

function useNotificationIcon(notification) {
  const theme = useTheme()

  return pickForNotification(
    {
      _default: (
        <Avatar sx={{ backgroundColor: 'priorityMedium.main', mr: 3 }}>
          <NotificationIcon color={theme.palette.priorityMedium.contrastText} />
        </Avatar>
      ),
      alert: {
        _default: (
          <Avatar sx={{ backgroundColor: 'priorityHigh.main', mr: 3 }}>
            <SiloIcon color={theme.palette.priorityHigh.contrastText} />
          </Avatar>
        ),
      },
      sensorInfos: {
        calibrating: (
          <Avatar sx={{ backgroundColor: 'priorityMedium.main', mr: 3 }}>
            <CalibrateIcon fill={theme.palette.priorityMedium.contrastText} />
          </Avatar>
        ),
        lowBattery: (
          <Avatar sx={{ backgroundColor: 'priorityHigh.main', mr: 3 }}>
            <Battery33PercentIcon
              color={theme.palette.priorityHigh.contrastText}
              height={24}
              width={24}
            />
          </Avatar>
        ),
        calibrated: (
          <Avatar sx={{ backgroundColor: 'ok.main', mr: 3 }}>
            <CalibrateIcon fill={theme.palette.ok.contrastText} />
          </Avatar>
        ),
      },
      order: {
        _default: (
          <Avatar sx={{ backgroundColor: 'priorityMedium.main', mr: 3 }}>
            <OrderIcon stroke={theme.palette.priorityMedium.contrastText} />
          </Avatar>
        ),
      },
      device: {
        askDelivery: (
          <Avatar sx={{ backgroundColor: 'priorityMedium.main', mr: 3 }}>
            <SiloIcon color={theme.palette.priorityMedium.contrastText} />
          </Avatar>
        ),
        askCapaMaxChange: (
          <Avatar sx={{ backgroundColor: 'priorityMedium.main', mr: 3 }}>
            <SiloIcon color={theme.palette.priorityMedium.contrastText} />
          </Avatar>
        ),
      },
    },
    notification
  )
}

function useNotificationHandler(notification) {
  const { payload, resourceId } = notification
  const newTabNavigate = useNewTabNavigate()
  const toastContext = useContext(ToastContext)
  const { openCalibDeliveryModal } = useCalibDeliveryModal()
  const { openDeviceModal } = useDeviceModal()

  return pickForNotification(
    {
      _default: () => toastContext.handleComingSoon(),
      alert: {
        _default: (event) => newTabNavigate(`/devices/${payload.device_reference}`, { event }),
      },
      sensorInfos: {
        _default: (event) => newTabNavigate(`/devices/${payload.device_reference}`, { event }),
      },
      order: {
        _default: (event) => newTabNavigate(`/orders/${resourceId}`, { event }),
      },
      device: {
        askCapaMaxChange: () => {
          // Cmd+Click not handled for now
          newTabNavigate(`/devices/${payload.device_reference}`, { newTab: false })
          openDeviceModal(true, {
            capacity: payload.newCapaMax,
          })
        },
        askDelivery: () => {
          // Cmd+Click not handled for now
          newTabNavigate(`/devices/${payload.device_reference}`, { newTab: false })
          openCalibDeliveryModal(true, {
            date: payload.date,
          })
        },
      },
    },
    notification
  )
}

function useNotificationPrimaryText(notification) {
  const { action, typeId, payload } = notification
  const { t, i18n } = useTranslation()

  return pickForNotification(
    {
      _default: t(`notification_${action}.${typeId}`, payload),
      alert: {
        _default: payload?.value
          ? t(`new_notification_alert.${typeId}`, payload)
          : t(`notification_alert.${typeId}`, payload),
      },
      device: {
        askCapaMaxChange: t(`notification_device.askCapaMaxChange`, {
          ...payload,
          capaMax: t('number_workspace_filling_unit', {
            value: payload.capaMax,
          }),
          newCapaMax: t('number_workspace_filling_unit', {
            value: payload.newCapaMax,
          }),
        }),
        askDelivery: t(`notification_device.askDelivery`, {
          device_name: payload.device_name,
          date: new Date(payload.date).toLocaleString(i18n.language, {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          }),
          time: new Date(payload.date).toLocaleString(i18n.language, {
            hour: 'numeric',
            minute: 'numeric',
          }),
        }),
      },
    },
    notification
  )
}

function useNotificationSecondaryText(notification) {
  const { payload, created_at } = notification
  const { i18n } = useTranslation()

  return pickForNotification(
    {
      _default: `${new Date(created_at).toLocaleString(i18n.language, {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      })} · ${payload.poi ?? 'unknown'}`,
    },
    notification
  )
}

function pickForNotification(mapping, { action, typeId }) {
  if (Object.hasOwn(mapping, action) && Object.hasOwn(mapping[action], typeId)) {
    return mapping[action][typeId]
  } else if (Object.hasOwn(mapping, action) && Object.hasOwn(mapping[action], '_default')) {
    return mapping[action]._default
  } else {
    return mapping._default
  }
}

function useNotificationProperties(notification) {
  return {
    icon: useNotificationIcon(notification),
    handler: useNotificationHandler(notification),
    primaryText: useNotificationPrimaryText(notification),
    secondaryText: useNotificationSecondaryText(notification),
  }
}

function NotificationCellContent({ notification, changeStatus }) {
  const { id, status } = notification
  const { icon, handler, primaryText, secondaryText } = useNotificationProperties(notification)

  return (
    <Stack
      justifyContent="space-between"
      flexDirection="row"
      height="100%"
      sx={{
        '& .MuiIconButton-root': {
          visibility: 'hidden',
        },
        ':hover': {
          '& .MuiIconButton-root': {
            visibility: 'visible',
          },
        },
      }}
    >
      <NanoMenuItem
        sx={{
          height: '100%',
          flexGrow: 1,
          ':hover': { backgroundImage: 'none', backgroundColor: 'transparent' },
        }} // important for center display, override background inherited from theme have a clean hover from Nanotable
        icon={icon}
        onClick={(event) => {
          handler?.(event)
          // Mark as opened
          if (status !== 'open') changeStatus(id, status)
        }}
        listItemTextProps={{
          sx: { textWrap: 'wrap' },
          primary: primaryText,
          secondary: secondaryText,
        }}
      />
      <IconButton onClick={() => changeStatus(id, status)}>
        <Badge
          sx={{
            '& .MuiBadge-badge': {
              right: 6,
              top: 5,
            },
          }}
          variant="dot"
          invisible={status === 'open'}
          color="priorityHighRevert"
        >
          <NotificationIcon />
        </Badge>
      </IconButton>
    </Stack>
  )
}

const propTypes = {
  loading: PropTypes.bool,
  status: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
  typeId: PropTypes.string.isRequired,
  payload: PropTypes.shape({ poi: PropTypes.string }).isRequired,
  created_at: PropTypes.string.isRequired,
  resourceId: PropTypes.string.isRequired,
  changeStatus: PropTypes.func.isRequired,
}

const defaultProps = { loading: false }

/**
 * Represent one Row of noticiation because only one column
 * use a lot of predefined constant because multiple use case possible.
 * Each row must have an icon, informations and callback when clicked
 *
 */
function NotificationCell({ changeStatus, loading, ...notification }) {
  const { status } = notification

  return (
    <TableCell
      sx={{
        backgroundColor: status !== 'open' ? 'greyBackground.main' : 'transparent',
        height: '100%',
        px: 2,
      }}
    >
      {loading ? (
        <>
          {/* Match the size of the listItem */}
          <Skeleton width="50%" sx={{ fontSize: 14 }} />
          <Skeleton width="30%" sx={{ fontSize: 12 }} />
        </>
      ) : (
        <NotificationCellContent notification={notification} changeStatus={changeStatus} />
      )}
    </TableCell>
  )
}

NotificationCell.propTypes = propTypes
NotificationCell.defaultProps = defaultProps

export default NotificationCell
